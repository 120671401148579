/**
 *Created by Mikael Lindahl on 2024-06-06
 */

import { useContext, useEffect } from "react";
import { UserStateContext } from "../context/UserStateProvider";
import clone from "../utils/clone";
import { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";

export type AddColumnVisibility = (columns: MUIDataTableColumnDef[]) => void;
const usePersistColumnVisibility = ({
  columnNames,
  id,
}: {
  columnNames: string[];
  id: string;
}) => {
  const { userState, setUserState } = useContext(UserStateContext);

  useEffect(() => {
    let isUpdate = false;
    const newState = columnNames.reduce((acc, name) => {
      if (!acc.table[id]) {
        acc.table[id] = {};
      }

      if (!userState.table[id]?.hasOwnProperty(name)) {
        acc.table[id][name] = true; // Set default value
        isUpdate = true;
      } else {
        acc.table[id][name] = userState.table[id][name]; // Keep existing value
      }
      return acc;
    }, clone(userState));
    if (isUpdate) {
      setUserState(newState);
    }
  }, []);

  const onColumnViewChange: MUIDataTableOptions["onColumnViewChange"] = (
    changedColumnName,
    action,
  ) => {
    const newState = clone(userState);

    if (!newState.table[id]) {
      newState.table[id] = {};
    }

    newState.table[id][changedColumnName] = action === "add";

    setUserState(newState);
  };

  const addColumnVisibility: AddColumnVisibility = (columns) => {
    if (typeof columns === "string") return;

    for (const c of columns) {
      if (typeof c === "string") continue;

      if (userState.table[id]) {
        if (c.options) {
          c.options.display = userState.table[id][c.name];
        }
      }
    }
  };

  return { addColumnVisibility, onColumnViewChange };
};

export default usePersistColumnVisibility;
