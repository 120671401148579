import { ContractOffer } from "src/accurasee-backend-types/app/contract_offer/contract_offer.types";
import { Customer } from "src/accurasee-backend-types/app/customer/customer.types";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { UserFormTableGetStructureOptional } from "src/hooks/useFormTable";
import getContractOfferExternalId from "src/utils/getContractOfferExternalId";

export interface GetStructureExtraProps {
  customers?: Customer[];
  grouping?: "active" | "archived";
  users?: ReturnUser[];
}

const getStructure: UserFormTableGetStructureOptional<
  ContractOffer,
  GetStructureExtraProps
> = (props) => ({
  items: [
    {
      dataName: "projectExternalId",
      headerLabel: "Offer Id",
      type: "link",
      getValue: ({ data, rowIndex }) =>
        getContractOfferExternalId(data[rowIndex]),
      redirectLink: (_props) =>
        `/app/contract-offers/overview/${String(_props.data[_props.rowIndex]._id)}`,
    },
    {
      type: "text",
      dataName: "name",
      headerLabel: "Offer name",
    },
    {
      type: "text",
      dataName: "description",
      headerLabel: "Description",
      sort: true,
    },
    {
      type: "text",
      dataName: "ownerIds",
      headerLabel: "Responsible",
      getValue: ({ data, rowIndex }) => {
        const owners =
          data[rowIndex]?.ownerIds?.map((ownerId) => {
            const owner = props?.extraProps?.users?.find(
              (e) => e._id === ownerId,
            );
            return `${owner?.firstName} ${owner?.lastName}`;
          }) || [];

        return owners?.join(", ") || " ";
      },
      sort: true,
    },
    {
      type: "text_currency",
      dataName: "offerPrice",
      headerLabel: "Offer price",
      alignColumnContent: "end",
      sort: true,
    },
    {
      type: "badge",
      dataName: "customerStatus",
      headerLabel: "Status",
      alignColumnContent: "center",
    },
    {
      type: "text",
      dataName: "customerId",
      headerLabel: "Customer",
      getValue: ({ data, rowIndex }) => {
        const customerId = data[rowIndex]?.customerId;
        return props?.extraProps?.customers?.find((c) => c._id === customerId)
          ?.name;
      },
    },
    {
      type: "date",
      dataName: "createdAt",
      headerLabel: "Created",
    },
    {
      type: "date",
      dataName: "deadline",
      headerLabel: "Deadline",
    },
    {
      showCellWhen: ({ data, rowIndex }) =>
        data[rowIndex]?.grouping === "archived",
      type: "icon",
      iconType: "check",
      dataName: "grouping",
      headerLabel: "Archived",
      alignColumnContent: "center",
    },
  ],
});

export const dataNames = getStructure().items.map((item) => item.dataName);
export default getStructure;
